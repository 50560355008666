import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import GiftsView from "./gifts";
import { useFriendState } from 'contexts/friend';

const FriendGiftsView = function() {
  let { id } = useParams();
  const { friend, setFriendID } = useFriendState();

  useEffect(() => {
    setFriendID(parseInt(id));
  }, [id])

  // If they aren't signed in, let's do that first
  if (!friend) {
    return null
  }
  return <GiftsView friend={friend} />
}

export default FriendGiftsView;
