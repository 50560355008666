import { createContext, useState, useContext } from "react";
import AuthContext from 'contexts/auth';

const FriendContext = createContext();

function useFriendState() {
  const { user } = useContext(AuthContext)
  const [friend, setFriend] = useState(null);

  const setFriendID = function(friendID) {
    if (!friendID || friendID === user.id) {
      return setFriend(user)
    }
    // Find the friend in the list of user friends
    const owner = user.friends.find((friend) => (friend.id === friendID))
    if (!owner) {
      return console.error("NO OWNER FOUND WITH ID", friendID)
    }
    setFriend(owner)
  }

  return {
    friend,
    setFriend,
    setFriendID,
  }
}

export default FriendContext
export { useFriendState };
