import _ from 'lodash';
import clsx from 'clsx';
import React, { useEffect, useCallback, useState, useContext } from 'react';
/*
import PropTypes from 'prop-types';
*/
import {
  Link as RouterLink,
  withRouter,
} from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import HomeIcon from '@material-ui/icons/Home';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
import HelpIcon from '@material-ui/icons/Help';
import { useSnackbar } from 'notistack';
import AuthContext from 'contexts/auth';
import { UserAvatar } from './helpers';
import { ShareLinkForm } from './userform';
import api from "api";
import tour from "./tour";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  dialogContent: {
    height: "300px",
    width: "400px",
    overflowY: "auto",
  },
  addFriendIcon: {
    color: theme.palette.getContrastText(theme.palette.primary.light),
    backgroundColor: theme.palette.primary.light,
  },
  requestsIcon: {
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    backgroundColor: theme.palette.secondary.light,
  },
  friendListSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
  scrollY: {
    height: "100%",
    overflowY: "auto",
  },
  approvePaper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}));


const AddFriendsDialog = function({open, onClose}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { addFriend } = useContext(AuthContext)
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const searchUsers = useCallback(
    _.debounce(async function (search) {
      if (search) {
        const users = await api.searchUsers(search)
        setUsers(users || [])
      }
      setLoading(false)
    }, 300),
    []
  )

  const add = async function(user) {
    await addFriend(user)
    enqueueSnackbar("Invite sent to " + user.name)
    onClose()
  }

  useEffect(() => {
    setLoading(true)
    setUsers([])
    searchUsers(searchVal)
  }, [searchVal, searchUsers])


  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Add friends</DialogTitle>
      <DialogContent>
        <TextField
          label="Search"
          placeholder="Name or email address"
          fullWidth
          margin="normal"
          variant="outlined"
          value={searchVal}
          onChange={(e) => { setSearchVal(e.target.value) }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null
          }}
        />
        <Box class={classes.dialogContent}>
          <List>
            { users.map((user) => (
              <ListItem button onClick={() => add(user)}>
                <ListItemAvatar>
                  <UserAvatar user={user} />
                </ListItemAvatar>
                <ListItemText primary={ user.name } />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const RequestListItem = function({user}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { approveRequest, denyRequest } = useContext(AuthContext)

  const approve = async function(reqUser) {
    setLoading(true)
    try {
      await approveRequest(reqUser)
      enqueueSnackbar(reqUser.name + " added to friend list")
    } catch {
    }
    setLoading(false)
  }

  const deny = async function(reqUser) {
    setLoading(true)
    try {
      await denyRequest(reqUser)
      enqueueSnackbar(reqUser.name + "'s invite declined")
    } catch {
    }
    setLoading(false)
  }

  return (
    <ListItem>
      <ListItemAvatar>
        <UserAvatar user={user} />
      </ListItemAvatar>

      <ListItemText primary={user.name} secondary={user.email}/>

      <ListItemSecondaryAction>
        { loading ? <CircularProgress /> : (
          <React.Fragment>
            <IconButton onClick={() => approve(user)}>
              <CheckIcon />
            </IconButton>
            <IconButton onClick={() => deny(user)}>
              <ClearIcon />
            </IconButton>
          </React.Fragment>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const RequestDialog = function({open, onClose}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext)

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Friend requests</DialogTitle>

      <DialogContent>
        <Box class={classes.dialogContent}>
          <List>
          { _.sortBy(user.requests, ["name"]).map((reqUser) => (
            <RequestListItem user={reqUser} />
          ))}
          </List>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ShareDialog = function({open, onClose}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext)

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Sharing your wishlist</DialogTitle>

      <DialogContent>
        <Box class={classes.dialogContent}>
          <Typography variant="h6">Public Links</Typography>
          <Typography variant="subtitle1" gutterBottom={true} color="textSecondary">Anyone can use these links to dib your gifts</Typography>
          <ShareLinkForm user={user} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const FriendList = withRouter(function({onClick}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext)
  const [openAddFriends, setOpenAddFriends] = useState(false);
  const [openRequests, setOpenRequests] = useState(false);

  return (
    <Box className={classes.scrollY}>
      <List component="nav">
        <ListSubheader className={classes.friendListSubheader}>Friends list</ListSubheader>
        { _.sortBy(user.friends || [], ["name"]).map((friend) => {
          return (
            <ListItem button component={RouterLink} to={"/p/gifts/" + friend.id} key={ friend.id } onClick={ onClick }>
              <ListItemAvatar>
                <UserAvatar user={friend} />
              </ListItemAvatar>
              <ListItemText primary={ friend.name } />
            </ListItem>
          )
        })}

        <Divider />

        <ListItem autoFocus button onClick={() => { setOpenAddFriends(true) }}>
          <ListItemAvatar>
            <Avatar className={classes.addFriendIcon}>
              <PersonAddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add friend" primaryTypographyProps={{variant: "button"}}/>
        </ListItem>

        { (user.requests || []).length > 0 &&
          <ListItem autoFocus button onClick={() => { setOpenRequests(true) }}>
            <ListItemAvatar>
              <Avatar className={classes.requestsIcon}>
                { (user.requests || []).length }
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Friend requests" primaryTypographyProps={{variant: "button"}}/>
          </ListItem>
        }
      </List>

      <RequestDialog
        user={user}
        open={openRequests}
        onClose={() => { setOpenRequests(false) }}
      />

      <AddFriendsDialog
        open={openAddFriends}
        onClose={() => { setOpenAddFriends(false) }}
      />
    </Box>
  )
})

const drawerWidth = 240;

const Navbar = withRouter(function(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { user, signout } = useContext(AuthContext);

  const handleSignout = () => {
    signout();
  }

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAccountMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const [openSharing, setOpenSharing] = useState(false);

  const handleSharingClick = () => {
    setOpenSharing(true);
  }

  const handleSharingClose = () => {
    setOpenSharing(false)
  }
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Tooltip title="Friends list" enterDelay={500}>
            <IconButton
              id={tour.elementIDs.showFriendsLink}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <Badge
                badgeContent={(user.requests || []).length}
                invisible={(user.requests || []).length === 0}
                color="secondary"
              >
                <MenuIcon />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title="My gifts" enterDelay={500}>
            <IconButton
              id={tour.elementIDs.homeLink}
              component={RouterLink}
              to={"/p/gifts/" + user.id}
              edge="start"
              aria-label="home"
              color="inherit"
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>

          <Link
            component={RouterLink}
            variant="h6"
            color="inherit"
            nowrap
            underline={"none"}
            className={classes.grow}
            to={"/p/gifts/" + user.id}
          >
            WeGotDibs!
          </Link>

          <Tooltip title="Help" enterDelay={500}>
            <IconButton
              edge="start"
              aria-label="help"
              color="inherit"
              onClick={(e) => { e.stopPropagation(); tour.start() }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Share your wishlist" enterDelay={500}>
            <IconButton
              id={tour.elementIDs.shareLink}
              edge="start"
              aria-label="share"
              color="inherit"
              onClick={handleSharingClick}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Account" enterDelay={500}>
            <IconButton
              id={tour.elementIDs.accountLink}
              edge="end"
              aria-label="account"
              color="inherit"
              onClick={handleAccountMenuClick}
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleAccountMenuClose}
          >
            <MenuItem
              component={RouterLink}
              to="/p/account"
              onClick={handleAccountMenuClose}
             >My account</MenuItem>
            <MenuItem
              component={RouterLink}
              to="/"
              onClick={handleSignout}
            >Logout</MenuItem>

          </Menu>
        </Toolbar>
      </AppBar>

      <ShareDialog
        open={openSharing}
        onClose={handleSharingClose}
      />

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <FriendList onClick={handleDrawerClose} />
      </Drawer>
    </div>
  )
})

export default Navbar;
