import React, { useState, useContext } from 'react';
import _ from "lodash";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import api from "api";
import AuthContext from "contexts/auth";
import { BasicInfoForm, CategoryForm, PasswordForm, UserValidator } from "../userform";
import { PromiseButton } from "../helpers";

const useStyles = makeStyles((theme) => ({
  section: {
    maxWidth: "400px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  navButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
}));

function CreateUser({onCreate}) {
  const classes = useStyles()
  const [user, setLocalUser] = useState({categories: ["Books", "Clothing", "Games", "Movies / TV", "Music"]})
  const [step, setStep] = useState(1)
  const [errors, setErrors] = useState({})
  const { signin } = useContext(AuthContext)

  const updateUser = function(updates) {
    // remove the errors of stuff that changed
    const newErrs = Object.assign({}, errors)
    _.forOwn(updates, (val, key) => {
      if (user[key] !== val && errors[key]) {
        delete newErrs[key]
      }
    })
    setErrors(newErrs)
    setLocalUser(Object.assign({}, user, updates))
  }

  const gotoPrevStep = function() {
    setStep(step - 1)
    setErrors({})
  }

  const gotoNextStep = function(validatorFn) {
    return async () => {
      const errs = await validatorFn(user)
      setErrors(errs)

      if (Object.keys(errs).length > 0) {
        return
      }
      setStep(step + 1)
    }
  }

  const createUser = async function() {
    const newUser = await api.saveUser(user)
    signin(newUser)
    onCreate && onCreate()
  }

  const renderStep = function() {
    switch (step) {
      case 1:
        return (
          <form onSubmit={gotoNextStep(UserValidator.validateLogin)}>
            <TextField
              label="Username"
              fullWidth
              required
              variant="outlined"
              margin="normal"
              value={user.username}
              error={!!errors.username}
              helperText={errors.username}
              onChange={(e) => { updateUser({username: e.target.value}) }}
            />
            <PasswordForm
              user={user}
              onChange={updateUser}
              errors={errors}
            />
            { renderNavButtons(gotoNextStep(UserValidator.validateLogin)) }
          </form>
        )
      case 2:
        return (
          <form onSubmit={gotoNextStep(UserValidator.validateBasicInfo)}>
            <BasicInfoForm
              user={user}
              onChange={updateUser}
              errors={errors}
            />
            { renderNavButtons(gotoNextStep(UserValidator.validateBasicInfo)) }
          </form>
        )
      case 3:
        return (
          <React.Fragment>
            <Typography variant="h5">Gift Categories</Typography>
            <Typography variant="subtitle1" color="textSecondary">Used to help organize your gifts. Can be changed later.</Typography>
            <CategoryForm user={user} onChange={updateUser} />
            { renderNavButtons(createUser) }
          </React.Fragment>
        )
      default:
        return null
    }
  }

  const renderNavButtons = function(nextFn) {
    return (
        <Box class={classes.navButtons}>
          { step !== 1 &&
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={ gotoPrevStep }
            >Back</Button>
          }
          <Box />
          <PromiseButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={Object.keys(errors).length > 0}
            endIcon={step !== 3 ? <ArrowForwardIcon /> : <ExitToAppIcon /> }
            onClick={nextFn}
          >{ step !== 3 ? "Next" : "Finish" }</PromiseButton>
        </Box>
    )
  }

  return (
    <Container maxWidth={"sm"}>
      <Typography variant="h2">Create Profile</Typography>
      <Box class={classes.section}>
        { renderStep() }
      </Box>

    </Container>
  )
}

export default CreateUser
