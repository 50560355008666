import React, {useState, createContext, useContext} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const ConfirmContext = createContext();

const useConfirm = function() {
  return useContext(ConfirmContext)
}

function ConfirmProvider({children}) {
  const [open, setOpen] = useState(false);
  const [dialogOpts, setDialogOpts] = useState({});
  const {title, body, onOK, onCancel} = dialogOpts;

  const confirm = function(title, body) {
    return new Promise((resolve, reject) => {
      setDialogOpts({
        title,
        body,
        onOK: resolve,
        onCancel: reject,
      })
      setOpen(true)
    });
  }

  const handleClose = function() {
    onCancel && onCancel()
    setOpen(false)
  }

  const handleOK = function() {
    onOK && onOK()
    setOpen(false)
  }


  return (
    <ConfirmContext.Provider value={{confirm}}>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        { body &&
          <DialogContent>
            <DialogContentText>{body}</DialogContentText>
          </DialogContent>
        }
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOK} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      { children }
    </ConfirmContext.Provider>
  )
}

export default ConfirmProvider
export { useConfirm };
