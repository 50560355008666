import _ from "lodash";
import { createContext, useState, useEffect, useContext } from "react";
import AuthContext from 'contexts/auth';
import api from "api";

const GiftContext = createContext();

function useGiftState(initialGifts, friendID) {
  const { user } = useContext(AuthContext)
  const [giftInfo, setGiftInfo] = useState({gifts: initialGifts, loadingMessage: ""});

  useEffect(() => {
    setGiftInfo({gifts: [], loadingMessage: "Loading gifts..."})
    api.fetchGifts(friendID).then((gifts) => {
      setGiftInfo({
        gifts,
        loadingMessage: "",
      })
    })
  }, [friendID])

  // Allows for individual gift changes, for dibs and for creating new gifts
  const updateLocalGift = async (giftID, newGift) => {
    if (!newGift) {
      newGift = await api.fetchGift(friendID, giftID)
    }
    let gifts   = _.cloneDeep(giftInfo.gifts)
    let oldGift = gifts.find((gift) => gift.id === newGift.id)
    // If we couldn't find it, assume it's new
    if (!oldGift) {
      gifts.push(newGift)
    } else {
      Object.assign(oldGift, newGift)
    }
    setGiftInfo({
      gifts,
      loadingMessage: "",
    })
  }

  const saveGift = async (gift) => {
    // Validation
    const newGift = await api.saveGift(user.id, gift)
    updateLocalGift(gift.id, newGift)
  }

  const deleteGift = async (gift) => {
    await api.deleteGift(user.id, gift.id)
    let gifts   = _.cloneDeep(giftInfo.gifts)
    _.remove(gifts, (g) => g.id === gift.id)
    setGiftInfo({
      gifts,
      loadingMessage: "",
    })
  }

  const createDib = async (dib) => {
    await api.createDib(friendID, dib)
    updateLocalGift(dib.gift_id)
  }

  const deleteDib = async (dib) => {
    await api.deleteDib(friendID, dib.id)
    updateLocalGift(dib.gift_id)
  }

  return { giftInfo, createDib, deleteDib, saveGift, deleteGift }
}

export default GiftContext
export {
  useGiftState,
}
