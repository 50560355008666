import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GiftsView from "./gifts";
import AuthContext from 'contexts/auth';
import { useFriendState } from 'contexts/friend';
import api from "api";

const useStyles = makeStyles((theme) => ({
  buttonForm: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  buttonGroup: {
    marginBottom: theme.spacing(4),
  },
}))

const SharedLoginView = function() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const redirect = function(uri) {
    const searchParams = "?next=" + encodeURIComponent(location.pathname)
    history.push(uri + searchParams)
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h2" align="center">Shared List</Typography>
      <Typography variant="body1" align="center" color="textSecondary">
        Before you can dib a gift, you need to sign in.
      </Typography>
      <Box class={classes.buttonForm}>
        <Box class={classes.buttonGroup}>
          <Button
            onClick={() => redirect("/p/guestsignin")}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PersonOutlineIcon />}
          >
            Guest Sign In
          </Button>
        </Box>

        <ButtonGroup
          variant="contained"
          color="primary"
          size="large"
          orientation="vertical"
        >
          <Button
            onClick={() => redirect("/p/signin")}
            startIcon={<AccountCircleIcon />}
          >
            Existing Profile
          </Button>
          <Button
            onClick={() => redirect("/p/createuser")}
            startIcon={<PersonAddIcon />}
          >
            New Profile
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  )
}

const SharedGiftsView = function() {
  let { id } = useParams();
  const { friend, setFriend } = useFriendState();
  const { isSignedIn } = useContext(AuthContext);

  useEffect(() => {
    // Need to get that share info
    const fn = async function() {
      const data = await api.getShareLink(id);
      api.setSharingLink(id);
      setFriend(data.user);
    }
    fn()
  }, [id, setFriend])

  // If they aren't signed in, let's do that first
  if (!isSignedIn) {
    return <SharedLoginView />
  }
  if (!friend) {
    return <CircularProgress color="inherit" />
  }
  return <GiftsView friend={friend} />
}

export default SharedGiftsView;
