import React, { useState, useEffect } from 'react';
import _ from "lodash";
import dayjs from "dayjs";
import DateUtils from '@date-io/dayjs';
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Popover,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { useConfirm } from "./confirm";
import api from "api";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const copyToClipboard = function(str) {
  if (navigator.clipboard) {
    // New hotness
    navigator.clipboard.writeText(str)
  } else {
    // Fallback to the old way
    const el = document.createElement('input');
    el.value = str;
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    el.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  };
};

const UserValidator = {
  validators: {
    // Login stuff
    username: async (val) => {
      if (!val) return "Required"
      if (val.length <= 3) return "Username must be longer than 3 characters"
      if (await api.usernameExists(val)) {
        return "Username already exists"
      }
    },
    passwd: async (val) => {
      if (!val) return "Required"
      if (val.length <= 3) return "Password must be longer than 3 characters"
    },
    // Basic info
    name: async (val) => {
      if (!val) return "Required"
    },
    email: async (val) => {
      if (!val) return "Required"
      if (!emailRegex.test(val)) return "Invalid email"
    },
  },

  async validateAttrs(user, attrs=[]) {
    let errs = {}

    // Await each field validator
    await Promise.all(attrs.map(async (attr) => {
      const validatorFn = UserValidator.validators[attr]
      const err = await validatorFn(user[attr])
      if (err) {
        errs[attr] = err
      }
    }))
    return errs
  },

  async validateLogin(user) {
    return UserValidator.validateAttrs(user, ["username", "passwd"])
  },

  async validateBasicInfo(user) {
    return UserValidator.validateAttrs(user, ["name", "email"])
  },
}

const useStyles = makeStyles((theme) => ({
  categories: {
    maxHeight: "20em",
    overflow: "auto",
  },
  categoryButtons: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    maxWidth: "300px",
  },
  section: {
    maxWidth: "400px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  newItemPaper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}));

function ShareLinkForm({user}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirm();
  const [links, setLinks] = useState([]);
  const [newLinkNotes, setNewLinkNotes] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  // Load up the links first
  useEffect(() => {
    let fn = async function() {
      const links = await api.getShareLinks(user.id)
      setLinks(links)
    }
    fn()
  }, [user.id])

  const deleteShareLink = async function(id) {
    await confirm("Are you sure you want to delete this link?")
    await api.deleteShareLink(user.id, id)
    setLinks(links.filter((link) => (link.id !== id)))
  }

  const createShareLink = async function(e) {
    e && e.preventDefault();
    const link = await api.createShareLink(user.id, {notes: newLinkNotes});
    setLinks(links.concat(link));
    toggleOpenNew();
  }

  const toggleOpenNew = function(e) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const copyLink = function(e) {
    e.preventDefault();
    copyToClipboard(e.target.href)
    enqueueSnackbar("Link copied to clipboard!")
  }

  const makeURL = function(link) {
    return <Link href={"/p/share/" + link.id} onClick={copyLink}>Copy sharing link</Link>
  }

  return (
    <Box>
      <List>
      { links.map((link) => {
        const {id, notes} = link;
        return (
          <ListItem key={id}>
            <ListItemText
              primary={makeURL(link)}
              secondary={notes}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => { deleteShareLink(id) }}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
      </List>

      <Box class={classes.categoryButtons}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={toggleOpenNew}
        >
          Add
        </Button>
      </Box>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={toggleOpenNew}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <form class={classes.newItemPaper} onSubmit={createShareLink}>
          <TextField
            label="Create Link"
            value={newLinkNotes}
            autoFocus
            placeholder="Name"
            helperText="To help manage multiple links"
            onChange={(e) => { setNewLinkNotes(e.target.value) }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  onClick={createShareLink}
                >
                  <AddCircleIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
      </Popover>
    </Box>
  )
}


function CategoryForm({user, onChange}) {
  const classes = useStyles();
  const [categories, setCategories] = useState((user.categories || []).sort());
  const [checked, setChecked] = useState({});
  const [newCategory, setNewCategory] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleOpenNewCategory = function(e) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const toggleCheck = function(category) {
    return function() {
      setChecked(Object.assign({}, checked, {[category]: !checked[category]}))
    }
  }
  const hasCheckedCategories = _.compact(_.values(checked)).length > 0

  const addNewCategory = function(e) {
    e && e.preventDefault();
    setCategories(categories.concat(newCategory))
    setNewCategory("")
    setAnchorEl(null)
  }

  const deleteCategories = function(e) {
    e && e.preventDefault();
    setCategories(categories.filter((category) => !checked[category]))
    setChecked({})
  }

  useEffect(() => {
    const updatedUser = {
      categories,
    }
    onChange && onChange(updatedUser)
  }, [categories])


  return (
    <form>
      <Box class={classes.categories}>
        <List>
        { categories.map((category) => {
          return (
            <ListItem key={category} dense button onClick={toggleCheck(category)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={!!checked[category]}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={category} />
            </ListItem>
          )
        })}
        </List>
      </Box>

      <Box class={classes.categoryButtons}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={!hasCheckedCategories}
          onClick={deleteCategories}
        >
          Delete
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={toggleOpenNewCategory}
        >
          Add
        </Button>

        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={toggleOpenNewCategory}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <form class={classes.newItemPaper} onSubmit={addNewCategory}>
            <TextField
              label="New Category"
              value={newCategory}
              autoFocus
              onChange={(e) => { setNewCategory(e.target.value) }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    onClick={addNewCategory}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
        </Popover>
      </Box>
    </form>
  )
}

function PasswordForm({user, errors={}, onChange}) {
  const [newPassword, setNewPassword] = useState(user.passwd)
  const [newPassword2, setNewPassword2] = useState(user.passwd)
  const [matchError, setMatchError] = useState("")

  useEffect(() => {
    setMatchError(newPassword && newPassword2 && newPassword !== newPassword2 ? "Passwords don't match" : "")
  }, [newPassword, newPassword2])

  // Send the new password up
  useEffect(() => {
    if (!matchError && newPassword && newPassword2) {
      onChange && onChange({passwd: newPassword})
    }
  }, [newPassword, newPassword2, matchError])

  return (
    <React.Fragment>
      <Box>
        <TextField
          type="password"
          label="Password"
          fullWidth
          required
          variant="outlined"
          margin="normal"
          error={!!errors.passwd}
          helperText={errors.passwd}
          value={ newPassword }
          onChange={ (e) => { setNewPassword(e.target.value) } }
        />
      </Box>

      <Box>
        <TextField
          type="password"
          label="Confirm password"
          fullWidth
          required
          variant="outlined"
          margin="normal"
          value={ newPassword2 }
          onChange={ (e) => { setNewPassword2(e.target.value) } }
          error={ !!matchError || !!errors.passwd}
          helperText={ matchError || errors.passwd }
        />
      </Box>
    </React.Fragment>
  )
}

// Used when creating a user and updating info
function BasicInfoForm({user, errors={}, onChange, onSubmit}) {
  const {name, email, giftIdeas} = user;
  // This is necessary because dayjs nulls when they try to type
  const [birthday, setBirthday] = useState(user.birthday ? dayjs(user.birthday) : null)

  const sendUpdates = function(attr, val) {
    onChange && onChange({[attr]: val})
  }

  const update = function(attr) {
    return (e) => {
      sendUpdates(attr, e.target.value)
    }
  }

  useEffect(() => {
    sendUpdates("birthday", birthday)
  }, [birthday])

  return (
    <form onSubmit={onSubmit}>
      <Box>
        <TextField
          label="Name"
          autoFocus
          required
          fullWidth
          variant="outlined"
          margin="normal"
          error={errors.name}
          helperText={errors.name || "This will be shown to other users"}
          onChange={update("name")}
          value={ name }
        />
      </Box>

      <Box>
        <TextField
          label="Email"
          type="email"
          required
          fullWidth
          variant="outlined"
          margin="normal"
          error={errors.email}
          helperText={errors.email}
          value={ email }
          onChange={update("email")}
        />
      </Box>

      <MuiPickersUtilsProvider utils={DateUtils}>
        <Box>
          <KeyboardDatePicker
            label="Birthday"
            margin="normal"
            inputVariant="outlined"
            format="MM/DD/YYYY"
            autoOk
            disableFuture
            value={birthday}
            onChange={(date) => { setBirthday(date) }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Box>
      </MuiPickersUtilsProvider>

      <Box>
        <TextField
          label="Gift Ideas"
          helperText="General ideas for gifts you'd like"
          placeholder={`Ex: "fuzzy socks, puzzles, anything Dr. Who"`}
          fullWidth
          multiline
          variant="outlined"
          margin="normal"
          value={ giftIdeas }
          onChange={update("giftIdeas")}
        />
      </Box>
    </form>
  )
}

export {
  BasicInfoForm,
  CategoryForm,
  PasswordForm,
  ShareLinkForm,
  UserValidator,
}
