import React, { createRef, useState, useEffect, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import {
  Box,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthContext from "contexts/auth";
import api from "api";
import { PromiseButton } from "../helpers";

const googleClientID = "393862830732-ph79stt1ru7br4pi571rqfmk71dgcisf.apps.googleusercontent.com"

const GoogleSignIn = function({onSignIn}) {
  const { signin } = useContext(AuthContext);
  const [initialized, setInitialized] = useState(false);
  const googleButtonRef = createRef(null);

  const handleGoogleResp = async function(resp) {
    const decodedToken = jwt_decode(resp.credential)
    try {
      let user = await api.loginGoogleToken(decodedToken)
      signin(user)
      onSignIn && onSignIn()
    } catch(err) {
      alert(err)
    }
  }

  useEffect(() => {
    if (!initialized) return
    window.google.accounts.id.initialize({
      client_id: googleClientID,
      callback: handleGoogleResp,
    });
    window.google.accounts.id.renderButton(googleButtonRef.current, {
      type: "standard",
      theme: "outline",
      size: "large",
      text: "signin_with",
      shape: "rectangular",
      logo_alignment: "left",
    });
  }, [initialized])

  // Just run once
  useEffect(() => {
    // Load the google api script
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = () => { setInitialized(true) }
    script.async = true;
    document.querySelector('body').appendChild(script)
  }, [])

  return (
    <div ref={googleButtonRef} />
  )
}

const SignIn = function({onSignIn}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { signin } = useContext(AuthContext)

  let submit = async function(e) {
    e && e.preventDefault()

    // Validation
    const errs = {}
    if (!username) errs.username = "Required"
    if (!password) errs.password = "Required"
    if (Object.keys(errs).length > 0) {
      return setErrors(errs)
    }

    let user = await api.loginUserPassword(username, password)
    signin(user)
    onSignIn && onSignIn()
  }

  useEffect(() => {
    // Clear errors if they changed
    const errs = Object.assign({}, errors)
    if (username && errs.username) delete errs.username
    if (password && errs.password) delete errs.password
    setErrors(errs)
  }, [username, password])

  return (
    <Container maxWidth="xs">
      <Typography variant="h2">Sign In</Typography>
      <form onSubmit={submit}>
        <Box>
          <TextField
            label="Username"
            variant="outlined"
            margin="normal"
            fullWidth={true}
            error={errors.username}
            helperText={errors.username}
            value={ username }
            onChange={(e) => { setUsername(e.target.value) }}
          />
        </Box>

        <Box>
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth={true}
            error={errors.password}
            helperText={errors.password}
            value={ password }
            onChange={(e) => { setPassword(e.target.value) }}
          />
        </Box>

        <PromiseButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={Object.keys(errors).length > 0}
          endIcon={<ExitToAppIcon />}
          fullWidth={true}
          onClick={submit}
        >Sign In</PromiseButton>
      </form>

      <Box mt="2em">
        <GoogleSignIn onSignIn={onSignIn} />
      </Box>
    </Container>
  )
}
export default SignIn;
