import _ from 'lodash';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Drawer,
  Divider,
  Fab,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Rating,
} from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import LaunchIcon from '@material-ui/icons/Launch';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LightbulbIcon from '@material-ui/icons/WbIncandescentOutlined';
import { useSnackbar } from 'notistack';
import { useConfirm } from "../confirm";
import { InView } from 'react-intersection-observer';
import AuthContext from "contexts/auth";
import GiftContext, { useGiftState } from "contexts/gift";
import { Overlay, StarHeader, PromiseButton, UserAvatar } from '../helpers'
import tour from "../tour";

function GiftEditor(props) {
  const { selectedGift, setSelectedGift, saveGift, deleteGift } = useContext(GiftContext);
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirm();

  const { user } = useContext(AuthContext)
  const [gift, setGift] = useState(selectedGift);

  useEffect(() => {
    setGift(Object.assign({
      // Need to have empty fields, since it appears if you don't supply
      // a field value it doesn't overwrite (might have something to do
      // with the <input>s
      id: undefined,
      name: "",
      notes: "",
      url: "",
      category: "",
      rating: 1,
    }, selectedGift))
  }, [selectedGift])

  const onClose = function() {
    setSelectedGift({})
  }

  const onSave = async function(e) {
    e && e.preventDefault()
    await saveGift(gift)
    enqueueSnackbar("Gift saved", {variant: "success"})
    onClose()
  }

  const onDelete = async function(e) {
    e && e.preventDefault()
    try {
      await confirm("Delete Gift", "Are you sure you want to delete this gift?")
    } catch {
      return
    }
    await deleteGift(gift)
    enqueueSnackbar("Gift deleted")
    onClose()
  }

  const onChangeGiftAttr = (attr) => {
    return (e) => {
      const val = e.target.value
      setGiftAttr(attr, val)
    }
  }

  const setGiftAttr = (attr, val) => {
    let g = _.cloneDeep(gift)
    g[attr] = val
    setGift(g)
  }

  return (
    <div className="h-100 w-100 py-2 px-4">
      <Typography variant="h5">{ !!selectedGift.id ? "Change" : "Add" } gift</Typography>
      <form class="mb-4">
        <Box>
          <TextField
            label="Name"
            variant="outlined"
            margin="normal"
            fullWidth={true}
            onChange={onChangeGiftAttr("name")}
            value={ gift.name }
          />
        </Box>

        <Box>
          <TextField
            select
            label="Category"
            variant="outlined"
            margin="normal"
            fullWidth={true}
            value={gift.category}
            onChange={onChangeGiftAttr("category")}
          >
            { user.categories.map((category) => {
                return <MenuItem value={ category }>{ category }</MenuItem>
              })
            }
          </TextField>
        </Box>

        <Box>
          <TextField
            label="Link"
            variant="outlined"
            margin="normal"
            fullWidth={true}
            onChange={onChangeGiftAttr("url")}
            value={ gift.url }
          />
        </Box>

        <Box>
          <TextField
            label="Notes"
            variant="outlined"
            margin="normal"
            fullWidth={true}
            multiline
            rows={3}
            onChange={onChangeGiftAttr("notes")}
            value={ gift.notes }
          />
        </Box>

        <Box>
          <Typography component="legend">Rating</Typography>
          <Rating max={3} value={ gift.rating } onChange={ onChangeGiftAttr("rating") } />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" my={"1em"}>
          <PromiseButton
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={onSave}
          >
            Save
          </PromiseButton>
          { gift.id &&
            <PromiseButton
              color="secondary"
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={onDelete}
            >
              Delete
            </PromiseButton>
          }
        </Box>
      </form>

      <Typography variant="h6">Preview</Typography>
      <GiftBlock gift={gift}  />
    </div>
  )
}

function DibEditor(props) {
  const { selectedGift, setSelectedGift, createDib } = useContext(GiftContext);
  //const [occasionID, setOccasionID] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    setSelectedGift({})
  }

  const submitDib = async () => {
    let dib = {
      gift_id: selectedGift.id,
      //occasion_id: occasionID,
      quantity: 1,
    }
    await createDib(dib)
    enqueueSnackbar("Dibbed!", {variant: "success"})
    onClose()
  }

  return (
    <Box onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} >
      { /*
      <Box>
        <TextField
          select
          style={{marginTop: 0}}
          label="Occasion"
          helperText="Optional - more functionality coming soon"
          fullWidth={true}
          margin="normal"
          value={occasionID}
          onChange={e => setOccasionID(parseInt(e.target.value))}
        >
          <MenuItem value={""}>--</MenuItem>
          <MenuItem value={""}>Birthday</MenuItem>
          <MenuItem value={""}>Christmas</MenuItem>
        </TextField>
      </Box>
      */ }
      <PromiseButton
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={submitDib}
      >Dib it</PromiseButton>
    </Box>
  )
}

function DibOverlay(props) {
  const { dibs } = props;
  const { confirm } = useConfirm();
  const { user } = useContext(AuthContext)
  const { deleteDib } = useContext(GiftContext);
  const ourDib = dibs.find((dib) => dib.user_id === user.id);

  const delDib = async () => {
    await confirm("Remove this dib?", "")
    deleteDib(ourDib)
  }

  return (
    <Overlay onClick={!!ourDib && delDib}>
      <h4 className="position-absolute" style={{top: 0, left: 0}}>
        { /* If it's our dib, let it be clickable to un-dib */ }
        <span className={"badge " + (!!ourDib ? "badge-success" : "badge-info")}>
          <i className="fas fa-check"></i> DIBBED { !!ourDib && " BY YOU" }
        </span>

      </h4>
    </Overlay>
  )
}


const amazonSearchParams = {
  "tag": "wegotdibs-20",
  "linkCode": "ur2",
  "linkId": "3c97dcf6dfc206b17e2b0bacb0fb5baa",
  "camp": "1789",
  "creative": "9325",
}

const withAmazonParams = function(orgURL) {
  // For amazon we want to add our nice tracking links
  const url = new URL(orgURL);
  const params = new URLSearchParams(url.search);
  Object.keys(amazonSearchParams).forEach((key) => {
    params.set(key, amazonSearchParams[key])
  })
  url.search = params.toString()
  return url.toString()
}

function GiftBlock(props) {
  const { isMyList } = useContext(GiftContext);
  const { gift, onClick, isSelected } = props
  const isDibbed = !_.isEmpty(gift.dibs)
  const dibRef = useRef(null)

  let giftURL = gift.url
  if (giftURL && giftURL.includes("amazon.com")) {
    giftURL = withAmazonParams(giftURL)
  }
  const scrollIntoView = function() {
    dibRef.current.scrollIntoView({behavior: "smooth", block: "nearest"})
  }

  return (
    <Card
      ref={dibRef}
      onClick={!isDibbed && onClick}
      raised={isSelected}
      style={{width: "20em", position: "relative"}}
    >
      { (!isMyList && isDibbed) && <DibOverlay dibs={gift.dibs}/> }
      <CardHeader
        title={ gift.name }
        subheader={ gift.category }
        action={giftURL && (
          <Tooltip title="View item" enterDelay={100}>
            <IconButton
              variant="link"
              color="primary"
              href={ giftURL }
              onClick={(e) => { e.stopPropagation() }}
              target="_blank"
              rel="noreferrer"
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        )}
      />

      { gift.notes &&
        <CardContent>
          <Typography variant="subtitle1">{ gift.notes }</Typography>
        </CardContent>
      }
      { !isMyList &&
        <Collapse in={isSelected} timeout="auto" onEntered={scrollIntoView} unmountOnExit>
          <Divider />
          <CardContent>
            <DibEditor />
          </CardContent>
        </Collapse>
      }
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    position: "sticky",
    top: -1,
    zIndex: 1020,

    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    padding: theme.spacing(2),
    lineHeight: "3em",
    display: "flex",
    flexDirection: "row",
  },
  stuck: {
    boxShadow: theme.shadows[3],
  },
  giftLoader: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.action.disabledBackground,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  sectionBody: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "stretch",
    padding: theme.spacing(2),
  },
  avatarHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  floatingButton: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 1100,
  }
}))

function GiftGroup(props) {
  const { rating, gifts, rootEl } = props
  const classes = useStyles();
  const { selectedGift, setSelectedGift } = useContext(GiftContext)
  const ratingGifts = gifts[rating]
  if (_.isEmpty(ratingGifts)) return null

  return (
    <div>
      <InView root={rootEl} threshold={[1]}>
        {({ inView, ref, entry }) => {
          return (
            <div ref={ref} className={[classes.sectionHeader, entry?.intersectionRatio < 1 ? classes.stuck : ""].join(" ")}>
              <StarHeader num={rating} />
            </div>
          )
        }}
      </InView>
      <div className={ classes.sectionBody }>
        { ratingGifts.sort((a, b) => { return a.id - b.id }).map((gift) => {
          const isSelected = selectedGift.id === gift.id
          const onClick = () => {
            setSelectedGift(isSelected ? {} : gift)
          }

          return (
            <div className="m-3">
              <GiftBlock key={gift.id} gift={gift} isSelected={isSelected} onClick={onClick} />
            </div>
          )
        })
        }
      </div>
    </div>
  )
}

function GiftOwnerDetails({ideas}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleOpen = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget)
  }

  return (
    <React.Fragment>
      <Tooltip title="Gift ideas">
        <IconButton onClick={toggleOpen} id={tour.elementIDs.giftIdeasLink}>
          <LightbulbIcon />
        </IconButton>
      </Tooltip>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={toggleOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper style={{padding: theme.spacing(2) + "px"}}>
          <Typography variant="h5" gutterBottom={true}>Gift ideas</Typography>
          <Typography variant="body1">{ ideas }</Typography>
        </Paper>
      </Popover>
    </React.Fragment>
  )
}

function Gifts({friend}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { confirm } = useConfirm();

  const giftsRef = useRef(null);
  const { user, removeFriend } = useContext(AuthContext)
  const [selectedGift, setSelectedGift] = useState({})
  const [filteredGifts, setFilteredGifts] = useState([])
  const [showDibbed, setShowDibbed] = useState(false);
  const isMyList = friend.id === user.id

  const { giftInfo, createDib, deleteDib, saveGift, deleteGift } = useGiftState([], friend.id);

  // Speed dial controls
  const [openDial, setOpenDial] = useState(false);
  const handleCloseDial = () => {
    setOpenDial(false);
  };
  const handleOpenDial = () => {
    setOpenDial(true);
  };

  const handleCloseDrawer = () => {
    setSelectedGift({})
  };

  useEffect(() => {
    if (isMyList) {
      setShowDibbed(false)
    }
  }, [isMyList])

  const toggleShowDibbed = () => {
    setShowDibbed(!showDibbed)
  }

  const unfriend = async () => {
    await confirm("Remove Friend", `Are you sure you want to remove ${ friend.name }?`)
    removeFriend(friend)
    history.push("/")
  }

  // When the gifts change, un-select any gifts
  useEffect(() => {
    setSelectedGift({})
  }, [giftInfo.gifts])

  // Figure out what filtered gifts to show
  useEffect(() => {
    let _gifts = giftInfo.gifts || []
    if (!showDibbed) {
      // Show the gifts that don't have dibs or are dibbed by the current user
      _gifts = _gifts.filter((gift) => {
          return _.isEmpty(gift.dibs) || !!gift.dibs.find((dib) => dib.user_id === user.id);
      })
    }
    setFilteredGifts(_.groupBy(_gifts, "rating"))
  }, [user.id, showDibbed, giftInfo.gifts])

  return (
    <GiftContext.Provider value={{selectedGift, setSelectedGift, isMyList, createDib, deleteDib, saveGift, deleteGift}}>
      <div className="h-100" style={{backgroundColor: "#f5f5f5"}}>
        { giftInfo.loadingMessage &&
          <Overlay>
            <div className={ classes.giftLoader }>
              <CircularProgress color="inherit" />
              <Typography variant="h5">&nbsp;{ giftInfo.loadingMessage }</Typography>
            </div>
          </Overlay>
        }
        <div className="d-flex overflow-hidden flex-column h-100">
          <Box className={classes.avatarHeader}>
            <UserAvatar user={friend} />
            <Typography variant="h4" className="m-3">
              { friend.name }
            </Typography>
            { friend.giftIdeas && (
              <GiftOwnerDetails ideas={friend.giftIdeas} />
            )}
          </Box>

          <div className="h-100" style={{overflowX: "hidden", overflowY: "auto"}} ref={giftsRef}>
            { [3, 2, 1].map((rating) => {
              return (
                <GiftGroup
                  rootEl={giftsRef.current}
                  key={rating}
                  rating={rating}
                  gifts={filteredGifts}
                />
              )
            })
            }
          </div>
        </div>

        { isMyList ?
          <React.Fragment>
            <Drawer anchor="right" open={!_.isEmpty(selectedGift)} onClose={handleCloseDrawer}>
              <div>
                <IconButton onClick={handleCloseDrawer}>
                  {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </div>
              <Divider />
              <GiftEditor />
            </Drawer>

            <Fab
              id={tour.elementIDs.newGiftLink}
              color="primary"
              aria-label="add"
              className={ classes.floatingButton }
              onClick={() => {setSelectedGift({rating: 1})} }
            >
              <AddIcon />
            </Fab>
          </React.Fragment>
        :
          <React.Fragment>
            <SpeedDial
              id={tour.elementIDs.friendActionsLink}
              ariaLabel="Actions"
              className={ classes.floatingButton }
              icon={<SpeedDialIcon />}
              onClose={handleCloseDial}
              onOpen={handleOpenDial}
              open={openDial}
              direction="up"
            >
              <SpeedDialAction
                icon={showDibbed ? <VisibilityIcon /> : <VisibilityOffIcon />}
                tooltipTitle={ (showDibbed ? "Hide" : "Show") + " dibbed gifts" }
                onClick={toggleShowDibbed}
              />
              { !user.isGuest &&
                <SpeedDialAction
                  icon={<DeleteIcon />}
                  tooltipTitle="Remove friend"
                  onClick={unfriend}
                />
              }
            </SpeedDial>
          </React.Fragment>
        }
      </div>
    </GiftContext.Provider>
  )
}

export default Gifts
