import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Toolbar,
  Typography,
  Zoom,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Link as RouterLink,
  withRouter,
} from "react-router-dom";

const steps = [
  "Create a profile",
  "Add gifts to your wishlist",
  "Share with friends",
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    height: "100%",
    padding: theme.spacing(4),
    overflowY:"auto",
    maxWidth: theme.breakpoints.md,
    backgroundColor: theme.palette.background.paper,
  },
  textBox: {
    marginTop: 0,
    marginBottom: theme.spacing(10),
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "700px",
  },
}))


const HomePage = function(props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <AppBar position="static">
        <Toolbar style={{gap: "1em"}}>
          <Box flex="1" />
          <Button component={RouterLink} to="/p/createuser" color="inherit">
            Create Profile
          </Button>

          <Button component={RouterLink} to="/p/signin" color="inherit">
            Sign In
          </Button>
        </Toolbar>
      </AppBar>

      <Box className={classes.content}>
        <Typography variant="h1" align="center">We Got Dibs</Typography>
        <Zoom in={true} timeout={1000}>
          <Typography
            variant="h5"
            align="center"
            gutterBottom={true}
          >Wishlists for people who like suprises!</Typography>
        </Zoom>
        <br/>
        <Box className={classes.textBox}>
          <Typography variant="subtitle1" gutterBottom={true} align="center">
            Other wishlists alert you when people get things off your list, but my favorite part of gift-giving is seeing the surprise and joy on people's faces, so I built this!
          </Typography>
        </Box>

        <Box mt="100px" className={classes.textBox}>
          <Typography variant="h3" align="center">How's it work?</Typography>

          <Stepper alternativeLabel>
            {steps.map((label) => (
              <Step key={label} active={true}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Typography variant="subtitle1" align="center">
            You and your friends can call dibs on people's gifts without alerting them.
            <br/>
            Voila! Simple gift management without ruining the surprise.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
export default withRouter(HomePage);
