import axios from "axios";

class API {
  constructor() {
    this.client = axios.create({
      //baseURL: window.location.origin,
      timeouts: 3000,
      withCredentials: true,
    })
    this.client.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      if (error.response) {
        return Promise.reject(error.response.data)
      }
      return Promise.reject(error);
    });
  }

  onUnauthorized(fn) {
  // For future requests, check for 401 and set user to empty if that's the case
    this.client.defaults.validateStatus = function (status) {
      if (status === 401) { fn() }
      return 200 <= status && status < 300; // default
    }
  }

  setSharingLink(id) {
    // For people using a sharing link, we add a header so we can still auth them
    this.client.defaults.headers.common["x-share-link-id"] = id
  }

  async loginUserPassword(username, password) {
    const response = await this.client.post("/login", {
      username: username,
      passwd: password,
    })
    return response.data
  }

  async guestLogin(email, name) {
    const response = await this.client.post("/guestlogin", {
      email: email,
      name: name,
    })
    return response.data
  }

  async usernameExists(username) {
    const response = await this.client.get("/check_username", {
      params: {
        username: username,
      },
    })
    return response.data.found
  }

  async loginGoogleToken(tokenInfo) {
    const response = await this.client.post("/oauth/google/logintoken", tokenInfo)
    return response.data
  }

  async searchUsers(search) {
    const response = await this.client.get("/friends/", {
      params: {
        q: search,
      },
    })
    return response.data
  }

  async requestFriend(user) {
    const response = await this.client.post("/friends/request/" + user.id)
    return response.data
  }

  async removeFriend(user) {
    const response = await this.client.delete("/friends/" + user.id)
    return response.data
  }

  async approveRequest(user) {
    const response = await this.client.post("/friends/approve/" + user.id)
    return response.data
  }

  async denyRequest(user) {
    const response = await this.client.post("/friends/deny/" + user.id)
    return response.data
  }

  async fetchGifts(friendID) {
    let response = await this.client.get(`/user/${ friendID }/gifts/`)
    return response.data
  }

  async fetchGift(friendID, giftID) {
    let response = await this.client.get(`/user/${ friendID }/gifts/${ giftID }`)
    return response.data
  }

  async saveGift(userID, gift) {
    // Validation
    if (gift.rating) {
      gift.rating = parseInt(gift.rating)
    }
    let uri = `/user/${ userID }/gifts/`
    let method
    if (!gift.id) {
      method = this.client.post
    } else {
      uri += gift.id
      method = this.client.put
    }
    const resp = await method(uri, gift)
    return resp.data
  }

  async deleteGift(userID, giftID) {
    const resp = await this.client.delete(`/user/${ userID }/gifts/${ giftID }`)
    return resp.data
  }

  async createDib(friendID, dib) {
    const resp = await this.client.post(`/user/${ friendID }/dib/`, dib)
    return resp.data
  }

  async deleteDib(friendID, dibID) {
    const resp = await this.client.delete(`/user/${ friendID }/dib/${ dibID }`)
    return resp.data
  }

  async saveUser(user) {
    // Remove empty values
    Object.keys(user).forEach((attr) => {
      if (!user[attr]) delete user[attr]
    })
    let uri = "/user/"
    let method
    if (!user.id) {
      method = this.client.post
    } else {
      uri += user.id + "/"
      method = this.client.patch
    }
    const resp = await method(uri, user)
    return resp.data
  }

  async getShareLink(id) {
    const resp = await this.client.get(`/share/` + id)
    return resp.data
  }

  async getShareLinks(userID) {
    const resp = await this.client.get(`/user/${ userID }/share/`)
    return resp.data
  }

  async createShareLink(userID, info) {
    const resp = await this.client.post(`/user/${ userID }/share/`, info)
    return resp.data
  }

  async deleteShareLink(userID, id) {
    const resp = await this.client.delete(`/user/${ userID }/share/${ id }/`)
    return resp.data
  }
}

export default new API()
