import _ from 'lodash';
import { createContext, useState, useEffect } from 'react';
import api from "api";

const AuthContext = createContext();
const userKey = "currentUser";

function getInitialUserState() {
  // TODO Check the token expiry
  let userJson = localStorage.getItem(userKey)
  if (userJson) {
    return JSON.parse(userJson)
  }
  return {}
}

function useUserState() {
  const [user, setUser] = useState(getInitialUserState());
  //const [isSignedIn, setIsSignedIn] = useState(!_.isEmpty(user));

  const isSignedIn = !_.isEmpty(user)

  // Save the user to local storage
  useEffect(() => {
    //setIsSignedIn(!_.isEmpty(user))
    localStorage.setItem(userKey, JSON.stringify(user))
  }, [user])

  const signin = function(user) {
    setUser(user)
  }

  const signout = function() {
    setUser({})
  }

  // For future requests, check for 401 and set user to empty if that's the case
  api.onUnauthorized(() => {
    if (isSignedIn) {
      signout()
    }
  })

  const saveUser = async function(updatedUser) {
    updatedUser.id = user.id
    const newUser = await api.saveUser(updatedUser)
    setUser(Object.assign({}, user, newUser))
  }

  const addFriend = async function(friend) {
    const newUser = await api.requestFriend(friend)
    setUser(Object.assign({}, user, newUser))
  }

  const removeFriend = async function(friend) {
    const newUser = await api.removeFriend(friend)
    setUser(Object.assign({}, user, newUser))
  }

  const approveRequest = async function(reqUser) {
    const newUser = await api.approveRequest(reqUser)
    setUser(Object.assign({}, user, newUser))
  }

  const denyRequest = async function(reqUser) {
    const newUser = await api.denyRequest(reqUser)
    setUser(Object.assign({}, user, newUser))
  }

  return {
    user,
    isSignedIn,
    signin,
    signout,
    saveUser,
    addFriend,
    removeFriend,
    approveRequest,
    denyRequest,
  }
}

export default AuthContext;
export {
  useUserState,
}
