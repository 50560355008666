import React, { useContext, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import AuthContext from 'contexts/auth';
import { BasicInfoForm, CategoryForm, PasswordForm, UserValidator } from "../userform";
import { PromiseButton } from "../helpers";

const useStyles = makeStyles((theme) => ({
  section: {
    maxWidth: "400px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function User() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const { user, saveUser } = useContext(AuthContext)
  const [basicInfo, setBasicInfo] = useState(user || {})
  const [basicInfoErrors, setBasicInfoErrors] = useState({})
  const [passwordChanges, setPasswordChanges] = useState({})

  const handleBasicInfoChange = function(changes) {
    setBasicInfo(Object.assign({}, basicInfo, changes))
  }

  const handleBasicInfoSave = async function() {
    // Check for errors
    const errors = UserValidator.validateBasicInfo(basicInfo)
    setBasicInfoErrors(errors)
    if (Object.keys(errors).length > 0) return

    await saveUser(basicInfo)
    enqueueSnackbar("User info updated")
  }

  const handlePasswordSave = async function() {
    await saveUser(passwordChanges)
    enqueueSnackbar("Password changed")
  }

  return (
    <Container maxWidth={"lg"}>
      <Box class={classes.section}>
        <Typography variant="h4" gutterBottom={true}>Basic Info</Typography>
        <BasicInfoForm user={basicInfo} onChange={handleBasicInfoChange} errors={basicInfoErrors} />
        <PromiseButton
          variant="contained"
          color="primary"
          disabled={Object.keys(basicInfoErrors).length !== 0}
          startIcon={<SaveIcon />}
          onClick={handleBasicInfoSave}
        >Save</PromiseButton>
      </Box>

      <Divider />

      <Box class={classes.section}>
        <Typography variant="h4" gutterBottom={true}>Categories</Typography>
        <CategoryForm user={user} onChange={saveUser} />
      </Box>

      <Divider />

      <Box class={classes.section}>
        <Typography variant="h4" gutterBottom={true}>Change Password</Typography>
        <PasswordForm user={passwordChanges} onChange={setPasswordChanges} />
        <PromiseButton
          variant="contained"
          color="primary"
          disabled={Object.keys(passwordChanges).length === 0}
          startIcon={<SaveIcon />}
          onClick={handlePasswordSave}
        >Change</PromiseButton>
      </Box>

    </Container>
  )
}

export default User
