// Import the required JavaScript and CSS on top of the component
import * as Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';


const elementIDs = {
  newGiftLink: "id-new-gift-link",
  friendActionsLink: "id-friend-actions",
  showFriendsLink: "id-show-friends-link",
  homeLink: "id-home-link",
  giftIdeasLink: "id-gift-ideas-link",
  accountLink: "id-account-link",
  shareLink: "id-share-link",
}

function start() {
  // then add a click handler or however you
  // might want to trigger it and initialize the Driver
  const driver = new Driver();
  // Define the steps for introduction
  driver.defineSteps([
    {
      element: '#' + elementIDs.newGiftLink,
      popover: {
        className: '',
        title: 'New gift',
        description: 'Click here to create a new gift',
        position: "top-right",
      }
    },
    {
      element: '#' + elementIDs.friendActionsLink,
      popover: {
        className: '',
        title: 'Friend actions',
        description: 'Hover or long-press here to see actions you can do, like showing dibbed gifts or removing this friend',
        position: "top-right",
      }
    },
    {
      element: '#' + elementIDs.giftIdeasLink,
      popover: {
        className: '',
        title: 'View gift ideas',
        description: 'In addition to specific gifts, people can add ideas for gifts',
        position: "bottom-right",
      }
    },
    {
      element: '#' + elementIDs.showFriendsLink,
      stageBackground: "blue",
      popover: {
        className: '',
        title: 'View friend list',
        description: 'Click here to view your friend list or add friends',
      }
    },
    {
      element: '#' + elementIDs.homeLink,
      stageBackground: "blue",
      popover: {
        className: '',
        title: 'Your list',
        description: 'Click here to be taken back to your list',
      }
    },
    {
      element: '#' + elementIDs.shareLink,
      stageBackground: "blue",
      popover: {
        className: '',
        title: 'Sharing',
        description: 'Click here to share your list',
        position: "bottom-right",
      }
    },
  ])
  driver.start()
}

export default {
  start,
  elementIDs,
}
