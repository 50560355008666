import React, {useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import {
  Rating,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.8,
    backgroundColor: theme.palette.background.paper,
  },
  buttonWrapper: {
    display: "inline-block",
    position: "relative",
  },
  buttonFullWidth: {
    display: "block",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Overlay(props) {
  let { children, ...otherProps} = props
  const classes = useStyles();
  return (
    <React.Fragment>
      <div
        className={"position-absolute " + classes.overlay}
        { ...otherProps }
      ></div>
      { children }
    </React.Fragment>
  )
}

function PromiseButton({onClick, ...props}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (!props.disabled && loading) {
    props.disabled = true;
  }

  const handleClick = async function(e) {
    e && e.preventDefault();
    if (loading) return

    setLoading(true);
    try {
      await onClick(e);
    } catch (err) {
      enqueueSnackbar(err, {variant: "error"});
    }
    setLoading(false);
  }

  return (
    <div className={[classes.buttonWrapper, props.fullWidth ? classes.buttonFullWidth : ""].join(" ")}>
      <Button
        {...props}
        onClick={handleClick}
      />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

function StarHeader({num}) {
  num = parseInt(num)
  return (
    <React.Fragment>
      <Rating max={ num } defaultValue={ num } readOnly size="large" />
      <Typography variant="h5">&nbsp;Rating</Typography>
    </React.Fragment>
  )
}

function UserAvatar({user}) {
  return (
    <Avatar alt={ user.name }>{ (user.name || "")[0] }</Avatar>
  )
}

export {
  Overlay,
  PromiseButton,
  StarHeader,
  UserAvatar,
}
