import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthContext from "contexts/auth";
import { PromiseButton } from "../helpers";
import api from "api";

const GuestSignIn = function({onSignIn}) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const { signin } = useContext(AuthContext)

  let submit = async function(e) {
    e && e.preventDefault()

    // Validation
    const errs = {}
    if (!email) errs.email = "Required"
    if (!name) errs.name = "Required"
    if (Object.keys(errs).length > 0) {
      return setErrors(errs)
    }

    let user = await api.guestLogin(email, name)
    signin(user)
    onSignIn && onSignIn()
  }

  useEffect(() => {
    // Clear errors if they changed
    const errs = Object.assign({}, errors)
    if (email && errs.email) delete errs.email
    if (name && errs.name) delete errs.name
    setErrors(errs)
  }, [email, name])

  return (
    <Container maxWidth="xs">
      <Typography variant="h2">Guest Profile</Typography>
      <form onSubmit={submit}>
        <Box>
          <TextField
            label="Email"
            helperText={errors.email || "Only used to uniquely identify you"}
            error={errors.email}
            fullWidth
            required
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box>
          <TextField
            label="Name"
            helperText={errors.name || "Will appear to other users"}
            error={errors.name}
            fullWidth
            required
            variant="outlined"
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <PromiseButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={Object.keys(errors).length > 0}
          endIcon={<ExitToAppIcon />}
          fullWidth
          onClick={submit}
        >
          Sign In
        </PromiseButton>
      </form>
    </Container>
  )
}

export default GuestSignIn;
