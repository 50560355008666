import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  CssBaseline,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import AuthContext, { useUserState } from './contexts/auth';
import Navbar from "./navbar";
import ConfirmProvider from "./confirm";
import FriendGiftsView from "./views/friendgifts";
import SharedGiftsView from "./views/sharedgifts";
import SignInView from "./views/signin";
import GuestSignInView from "./views/guestsignin";
import CreateUserView from "./views/createuser";
import AccountView from "./views/account";
import HomeView from "./views/home";

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  flexColumnScroll: {
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

function PrivateRoute({ children, render, ...rest }) {
  const { isSignedIn, user } = useContext(AuthContext);

  const renderChildren = function(props) {
    if (children) return children
    if (render) return render(props)
    return null
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;
        return isSignedIn && !user.isGuest ? renderChildren(props) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }}
    />
  );
}

function NavHeader({children}) {
  const classes = useStyles();
  const { isSignedIn, user } = useContext(AuthContext);

  return (
    <div className={ classes.flexColumn }>
      { isSignedIn && !user.isGuest ? <Navbar /> : null }
      <div className={ classes.flexColumnScroll }>
        { children }
      </div>
    </div>
  )
}


function App() {
  const userState = useUserState();
  const { user, isSignedIn } = userState;

  const renderRedirect = function() {
    return <Redirect to={isSignedIn && !user.isGuest ? "/p/gifts/" + user.id : "/"} />
  }

  const renderNext = function({history, location}) {
    let params = new URLSearchParams(location.search)
    let next = params.get("next") || "/p/gifts" + user.id
    return () => history.push(next)
  }

  return (
    <Router className="h-100">
      <ConfirmProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
      <AuthContext.Provider value={userState}>
        <CssBaseline />
        <div className="h-100 p-0 overflowy-auto">
          <Switch>
            <Route path="/" exact>
              <HomeView />
            </Route>

            <Route path="/p/signin" render={(routeInfo) => (
              <SignInView onSignIn={renderNext(routeInfo)} />
            )}>
            </Route>

            <Route path="/p/guestsignin" render={(routeInfo) => (
              <GuestSignInView onSignIn={renderNext(routeInfo)} />
            )}>
            </Route>

            <Route path="/p/createuser" render={(routeInfo) => (
              <CreateUserView onCreate={renderNext(routeInfo)} />
            )}>
            </Route>

            { /* Paths that should show the navbar, if the user is signed in */ }
            <Route path="*">
              <NavHeader>
                <Switch>
                  { /* this is relevant to both public and private users */ }
                  <Route path="/p/share/:id">
                    <SharedGiftsView />
                  </Route>

                  <PrivateRoute path="/p/gifts/:id">
                    <FriendGiftsView />
                  </PrivateRoute>

                  <PrivateRoute path="/p/account">
                    <AccountView />
                  </PrivateRoute>

                  <Route path="*" render={renderRedirect} />
                </Switch>
              </NavHeader>
            </Route>

          </Switch>
        </div>
      </AuthContext.Provider>
      </SnackbarProvider>
      </ConfirmProvider>
    </Router>
  )
}

export default App;
